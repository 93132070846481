"use strict";

// We're preventing the expansion of the navbar if there's also an anchor bar on the page
// import { isAnchoredPage } from "./scroll-to-anchor";
import {CONDENSED_NAV_HEIGHT} from "./config";
const nav = document.querySelector('#main-nav');

if (nav) {
  const topNavBar = nav.querySelector('#topNavBar');
  // const navMenu = nav.querySelector('#nav-menu-mobile');
  
  const body = document.querySelector('body');
  // const navAccordions = navMenu.querySelectorAll(".isParentNavItem");
  // const toggleNavBtn = topNavBar.querySelector("#toggle-nav-btn");
  const mainContent = document.querySelector('[role="main"]');
  
  // Helps us to 'lock' the window's scrolled position when toggling the fixed overlay
  // nav menu; otherwise, it'll shoot the user back to the top of the page every time
  let topScrollPosn;
  
  //---------------------------------------------
  // Nav scrolling animation - hides on scroll down,
  // reveal on scroll up
  // ** Preventing this behavior (to expand back to full height) on pages with an anchored nav bar **
  //---------------------------------------------
    const navScrollAnim = gsap.from(topNavBar, { 
      height: CONDENSED_NAV_HEIGHT,
      paused: true,
      duration: 0.1,
    }).progress(1);
    
    ScrollTrigger.create({
      start: "top top",
      end: 99999,
      onUpdate: (self) => {
          //   When scrolling up, reveal the full height of nav.
          //   Otherwise, condense the height while scrolling down.
          if (self.direction === -1) {
            navScrollAnim.play();
            topNavBar.classList.add('fullyOpen');
          } else {
            navScrollAnim.reverse();
            topNavBar.classList.remove('fullyOpen');
          }
      }
    });
  
}

