'use strict';
 
//---------------------------------------------
// HOW TO REFERENCE:
// https://shaktisinghcheema.com/how-to-autoplay-video-on-mobile-devices-on-low-power-mode/
// NOTE: ** Note: In low power mode, video cannot be played unless user interact with the device **
//---------------------------------------------

Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
    get: function () {
        return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
    }
});

function playVideoOnLowPower() {
    try {
        const videoElements = document.querySelectorAll("video");
        for (let i = 0; i < videoElements.length; i++) {
            if (videoElements[i].playing) {
                // video is already playing so do nothing
                // console.log('Playing');
            }
            else {
                // video is not playing so play video now
                videoElements[i].play();
                // console.log('Not Playing');
            }
        }
    }
    catch(err) {
        console.log(err);
    }
}

document.body.addEventListener("click", playVideoOnLowPower);
document.body.addEventListener("scroll", playVideoOnLowPower);
document.body.addEventListener("touchstart", playVideoOnLowPower);
