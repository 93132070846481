'use strict';

// See ignyte.dev2.thinkfullcircle.com nav scripts for clip path and reveal of text
// Also see csm.dev2.thinkfullcircle.com for more looping examples
// otter.dev2.thinkfullcircle.com also

import { prefersReducedMotionMediaQueryDetected } from "./config";

const textSectionsToReveal = [
  '[data-block-name="hp_hero_banner_bloq"] .container *', 
  '[data-block-name="features_section"] .feature-img-text-content *',
  '[data-block-name="text_over_image_row"] .container *',
  '[data-block-name="image_and_text_section"] *',
];
const toRevealWithStagger = [
  '[data-block-name="features_section"] .card',
  '[data-block-name="list_bar"] ul li',
  '[data-block-name="leadership_section"] .leadership-member-container'
]

const staggeredElsToReveal = gsap.utils.toArray(toRevealWithStagger);

//---------------------------------------------
// Specific elements to target delayed reveals for Homepage only
//---------------------------------------------
const doHomepageScrollReveals = function() {
  const hpHeroBannerBloqIntro = document.querySelector('[data-block-name="hp_hero_banner_bloq"] .hero-banner-copy .container');
  const hpHeroBannerBloqIntroHeading = document.querySelector('[data-block-name="hp_hero_banner_bloq"] h1');
  
  //---------------------------------------------
  // Transition reveal of h1 in Intro Section on Homepage
  //---------------------------------------------
  let tl = gsap.timeline({defaults: {ease: "power1.out", duration: 0.6}});
  
  if (hpHeroBannerBloqIntroHeading && hpHeroBannerBloqIntro) {

    tl.from(hpHeroBannerBloqIntroHeading, {
      opacity: 0,
      // y: '20%', // 20% of the height of the element
      delay: .5,
    }).from(hpHeroBannerBloqIntro, {
      opacity: 0,
      delay: 0.25,
    })
  }
}

//---------------------------------------------
// Stagger slide up and opacity reveal of Copy Columns Row items
// NOTE: 'autoAlpha' = opacity almost; see docs https://greensock.com/docs/v3/GSAP/CorePlugins/CSSPlugin
//---------------------------------------------
const doStaggeredReveals = function() {
  gsap.set(staggeredElsToReveal, {y: 30, autoAlpha: 0});
  ScrollTrigger.batch(staggeredElsToReveal, {
    onEnter: batch => gsap.to(batch, {
      autoAlpha: 1, 
      stagger: 0.4, 
      y: 0
    }),
    start: "top 80%",
    end: "top top",
    once: true,
    // markers: true,
  });
}

window.addEventListener('load', function() {
  if (!prefersReducedMotionMediaQueryDetected) {
    doHomepageScrollReveals();
  }
  if (staggeredElsToReveal.length > 0) {
    // console.log(staggeredElsToReveal);
    doStaggeredReveals();
  }
})


if (!prefersReducedMotionMediaQueryDetected) {

  // Text elements to reveal and slide up on scroll trigger
  const textElsToReveal = gsap.utils.toArray(textSectionsToReveal);

  textElsToReveal.forEach(el => {
    gsap.from(el, {
      opacity: 0,
      y: '10%',
      ease: "power1.out", 
      scrollTrigger: {
        trigger: el,
        start: "top 80%",
        once: true,
      }
    });
  });

//   // Images fading in on scroll trigger
  const imgsToReveal = gsap.utils.toArray("img[loading='lazy']:not(.dont-animate)");
  imgsToReveal.forEach((img) => img.style.opacity = '0');
  imgsToReveal.forEach(img => {
    gsap.to(img, {
      opacity: 1,
      ease: "power1.out", 
      duration: 1,
      scrollTrigger: {
        trigger: img,
        start: "top 80%",
        once: true, 
        // markers:true,
      }
    });
  });


} else {
  console.log('no animations');
}


