'use strict';

//---------------------------------------------
// Because we can't do calculations with the {bloqs:count:at:depth} within
// the template, we're manually generating the number count of the card
//---------------------------------------------
const cardCounters = document.querySelectorAll('.card-count');

if (cardCounters) {
    cardCounters.forEach((cardCountItem, i) => {
        cardCountItem.textContent = `0${i+1}`;
    })
}