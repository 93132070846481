import smoothscroll from "smoothscroll-polyfill";
// Base level imports

// IMPORT CONSTANT variables
import './components/config.js'; 
 

import "./components/utilities.js"; 
import "./components/cookie.js"; // for Notice message option  

// ****************************************
// Modified from base level 'imports'

import "./components/navigation.js";
import "./components/card-count-generator.js";
import "./components/scroll-animations";
import "./components/video-autoplay-low-power-mode.js";

import "./styles/main.scss";

smoothscroll.polyfill();

// gsap.registerPlugin(ScrollTriggerPlugin);

